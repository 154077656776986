import { StatusBar } from "expo-status-bar";
import { useWindowDimensions, Pressable, Platform, StyleSheet, ScrollView, Text, useColorScheme } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState, useCallback } from "react";
import { View } from "../components/Themed";
import ColorPicker from 'react-native-wheel-color-picker'
import space from 'color-space';
import { DatePickerModal } from 'react-native-paper-dates';
import { useQuery, gql, useMutation } from "@apollo/client";
import Config from "../constants/Config";
import { Picker } from "react-native-web";

const LOCATIONS_QUERY = gql`
  query getLocations($token: String!) {
    locations(token: $token) {
      id
      name
      city
      fp
    }
  }
`;

const OPERATORS_QUERY = gql`
  query getOperators($token: String!) {
    operators(token: $token) {
      id
      name
      role
    }
  }
`;

const RECEIPTS_QUERY = gql`query getReceipts($token: String!, $dateFrom: String!, $dateTo: String!) {
  receipts(token: $token, dateFrom: $dateFrom, dateTo: $dateTo){
    id
    operator
    timestamp
    title
    type
    description
    location
    receiptRequest {
      uniqueSaleNumber
      items {
        text,
        quantity,
        unitPrice
        taxGroup
      }
      payments {
        amount
        paymentType
      }
    }
  }
}`;

const PRINT_RECEIPT = gql`
  mutation printReceipt(
    $token: String!
    $operator: String!
    $type: String!
    $device: String!
    $id: ID!
  ) {
    printReceipt(
      token: $token
      operator: $operator
      type: $type
      device: $device
      id: $id
    )
  }
`;


export default function OperatorSettingsReceiptsScreen() {
  const [operatorId, setOperatorId] = useState("");
  const [token, setToken] = useState("");
  const [device, setDevice] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const window = useWindowDimensions();
  const colorScheme = useColorScheme();

  const [range, setRange] = React.useState({ startDate: new Date(), endDate: new Date() });
  const [expand, setExpand] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [blacklist, setBlacklist] = React.useState([])

   const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = React.useCallback(
      ({ startDate, endDate }) => {
        setOpen(false);
        setRange({ startDate, endDate });
      },
      [setOpen, setRange]
    );


  useEffect(() => {
    async function fetchData() {
      try {
        const storedId = await AsyncStorage.getItem("operatorId");
        setOperatorId(storedId);
      } catch (ex) {}

      try {
        const storedToken = await AsyncStorage.getItem("token");
        setToken(storedToken);
      } catch (ex) {}

      try {
        const storedAdminToken = await AsyncStorage.getItem("adminToken");
        setAdminToken(storedAdminToken);
      } catch (ex) {}
    }
    fetchData();
  }, []);

  const { loading, error, data } = useQuery(RECEIPTS_QUERY, {
    variables: { token: adminToken, dateFrom: range.startDate, dateTo: range.endDate },
  });

  let operatorsMap = {}
  const { data: operators_data } = useQuery(OPERATORS_QUERY, {
    variables: { token: token },
  });
  if (operators_data && operators_data.operators) {
    for (let operator of operators_data.operators) {
      operatorsMap[operator.id] = operator.name
    }
  }

  const locationMap = {}
  const locationNameMap = {}
  const { data: data_locations } = useQuery(LOCATIONS_QUERY, {
    variables: { token },
  });

  if (data_locations && data_locations.locations) {
    for (let locationKey of Object.keys(data_locations.locations)) {
      locationMap[data_locations.locations[locationKey].id] = data_locations.locations[locationKey].fp
      locationNameMap[data_locations.locations[locationKey].id] = data_locations.locations[locationKey].name
    }
  }

  const [
    printReceipt,
  ] = useMutation(PRINT_RECEIPT);
  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error :(</Text>;

  return (
        <View style={{flex: 1}}>
          <Pressable onPress={() => setOpen(true)}>
            <View style={{backgroundColor: Config.companyColor, padding: 15, borderRadius: 12, marginTop: 4 }}><Text style={{color: 'white'}}>Pick range</Text></View>
          </Pressable>
          <DatePickerModal
            locale="en"
            mode="range"
            visible={open}
            onDismiss={onDismiss}
            startDate={range.startDate}
            endDate={range.endDate}
            onConfirm={onConfirm}
          />
          <ScrollView>
            {
              data && data.receipts ? data.receipts.map(receipt => (
                <Pressable key={receipt.id} onPress={() => setExpand(receipt.id)} style={{padding: 15, borderWidth: 1, borderColor: 'white', borderRadius: 10, marginTop: 5, marginBottom: 5}}>
                  <Text style={{color: 'white'}}>{(new Date(receipt.timestamp)).toString()} {receipt.title}</Text>
                  {expand === receipt.id ? <View>
                    <Text style={{color: 'white'}}>{receipt.description} {receipt.receiptRequest.uniqueSaleNumber}</Text>
                    <Text style={{color: 'white'}}>{receipt.receiptRequest.payments[0].amount} {receipt.receiptRequest.payments[0].paymentType} {operatorsMap[receipt.operator]}</Text>
                    <View>
                      {receipt.receiptRequest.items.map((item, index) => (
                        <View key={index}>{item.quantity ? <Text style={{color: 'white'}}>{item.quantity} x {item.unitPrice} x {item.text}</Text> : null}</View>
                      ))}
                    </View>
                    <View style={{padding: 25}}>
                      {(blacklist.indexOf(receipt.id) === -1) ? <View style={{width: 100, flexDirection: 'row'}}>
                        
                        
                        <Picker selectedValue={device} onValueChange={value => {
                          setDevice(value)
                        }}>
                          <Picker.Item label={''} value={''} />
                          {
                            Object.keys(locationMap).map(location => (
                              <Picker.Item label={locationNameMap[location] + ' ' + locationMap[location] + (location === receipt.location ? '*' : '')} value={locationMap[location]} />
                            ))
                          }
                        </Picker>
                        <View style={{width: 100, padding: 20}}>
                        <Pressable onPress={() => {
                          if (device) {
                            setBlacklist([
                              ...blacklist,
                              receipt.id
                            ])

                            printReceipt({
                              variables: {
                                token: adminToken,
                                operator: operatorId,
                                type: receipt.type,
                                device: device,
                                id: receipt.id
                              }
                            })
                          } else {

                          }

                      }} style={{paddingLeft: 10, backgroundColor: 'red', borderRadius: 10}}>
                        <Text style={{color: 'white'}}>Print</Text>
                      </Pressable>
                      </View>
                      </View> : null}
                    </View>
                  </View> : null}
                </Pressable>
              )) : null
            }
          </ScrollView>

          <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
        </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  lightThemeColors: {},
  darkThemeColors: {
    borderColor: "white",
    color: "white",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
