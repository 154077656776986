import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { View, Text } from "react-native";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import QRCodeModalScreen from "../screens/QRCodeModalScreen";
import PublicTableScreen from "../screens/PublicTableScreen";
import PublicComputerScreen from "../screens/PublicComputerScreen";
import TableDetailsScreen from "../screens/TableDetailsScreen";
import DeliveryDetailsScreen from "../screens/DeliveryDetailsScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import MenuScreen from "../screens/MenuScreen";
import VRScreen from "../screens/VRScreen";
import MoreScreen from "../screens/MoreScreen";
import EsportsScreen from "../screens/EsportsScreen";
import TablesScreen from "../screens/TablesScreen";
import OrdersScreen from "../screens/OrdersScreen";
import DeliveriesScreen from "../screens/DeliveriesScreen";
import OperatorSettingsScreen from "../screens/OperatorSettingsScreen";
import OperatorSettingsLightsAndColorsScreen from '../screens/OperatorSettingsLightsAndColorsScreen';
import OperatorSettingsSummaryScreen from '../screens/OperatorSettingsSummaryScreen';
import OperatorSettingsReportScreen from '../screens/OperatorSettingsReportScreen';
import OperatorSettingsInviteScreen from '../screens/OperatorSettingsInviteScreen';
import OperatorSettingsProfileScreen from '../screens/OperatorSettingsProfileScreen';
import OperatorSettingsReceiptsScreen from "../screens/OperatorSettingsReceipts";
import OperatorBenefitsScreen from "../screens/OperatorBenefitsScreen";
import Config from "../constants/Config";
import OperatorComputersScreen from "../screens/OperatorComputersScreen";
import OperatorVRScreen from "../screens/OperatorVRScreen";
import PublicInviteScreen from "../screens/PublicInviteScreen";
import LinkingConfiguration from "./LinkingConfiguration";
import Logo1 from "../components/Logo1";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Octicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OperatorGamingDetailsScreen from "../screens/OperatorGamingDetailsScreen";
import i18n from "../utils/localization";

export default function Navigation({
  colorScheme,
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PublicInvite"
        title="Create your account"
        component={PublicInviteScreen}
        options={{ headerShown: false, title: "Create your account" }}
      />
      <Stack.Screen
        name="OperatorRoot"
        component={OperatorBottomTabNavigator}
        options={{ headerShown: false }}
      />
      
      {Config.modules.delivery && <Stack.Screen
        name="DeliveriesRoot"
        component={DeliveriesRoot}
        options={{ headerShown: false }}
      /> }

      <Stack.Screen
        name="OrdersRoot"
        component={OrdersScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{
          title: "Oops!",
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
        }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="QRCodeModal"
          component={QRCodeModalScreen}
          options={{
            title: "Harlem Table QR Code",
            headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          }}
        />
        <Stack.Screen
          name="QRCodeModalPC"
          component={QRCodeModalScreen}
          options={{
            title: "Harlem Gaming QR Code",
            headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

function TablesRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="Tables"
        path=""
        component={TablesScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="TableDetails"
        component={TableDetailsScreen}
        options={{ headerShown: true, title: 'Table details',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
    </Stack.Navigator>
  );
}


function DeliveriesRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="Deliveries"
        path=""
        component={DeliveriesScreen}
        options={{ headerShown: true, title: i18n.t('Deliveries') }}
      />
      <Stack.Screen
        name="DeliveryDetails"
        component={DeliveryDetailsScreen}
        options={{ headerShown: true, title: 'Delivery details',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
    </Stack.Navigator>
  );
}


function OperatorSettingsRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="OperatorSettings"
        path=""
        component={OperatorSettingsScreen}
        options={{ headerShown: false, title: 'Settings' }}
      />
      <Stack.Screen
        name="OperatorSettingsLightsAndColors"
        component={OperatorSettingsLightsAndColorsScreen}
        options={{ headerShown: true, title: 'Lights & colors',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
      <Stack.Screen
        name="OperatorSettingsReceipts"
        component={OperatorSettingsReceiptsScreen}
        options={{ headerShown: true, title: 'Receipts',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
      <Stack.Screen
        name="OperatorSettingsProfile"
        component={OperatorSettingsProfileScreen}
        options={{ headerShown: true, title: 'Profile',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
      <Stack.Screen
        name="OperatorSettingsSummary"
        component={OperatorSettingsSummaryScreen}
        options={{ headerShown: true, title: 'Summary',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
      <Stack.Screen
        name="OperatorSettingsReport"
        component={OperatorSettingsReportScreen}
        options={{ headerShown: true, title: 'Report',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
      <Stack.Screen
        name="OperatorSettingsInvite"
        component={OperatorSettingsInviteScreen}
        options={{ headerShown: true, title: 'Invite',
        headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily }, }}
      />
    </Stack.Navigator>
  );
}

function GamingRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="Gaming"
        path=""
        component={OperatorComputersScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GamingDetails"
        component={OperatorGamingDetailsScreen}
        options={{ headerShown: true, title: 'Gaming details' }}
      />
    </Stack.Navigator>
  );
}

function VRRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="VRGaming"
        path=""
        component={OperatorVRScreen}
        options={{ headerShown: false, title: 'VR gaming' }}
      />
      <Stack.Screen
        name="VRGamingDetails"
        component={OperatorGamingDetailsScreen}
        options={{ headerShown: true, title: 'VR details' }}
      />
    </Stack.Navigator>
  );
}

function MenuRoot() {
  return (
    <Stack.Navigator path="">
      <Stack.Screen
        name="Menu"
        component={MenuScreen}
        options={{
          title: Config.menuTitle,
          headerShown: false,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <Text
              style={{
                fontFamily: Config.theme.defaultFontFamily,
                textShadowColor: color,
                textShadowOutline: 1,
                textShadowRadius: 3,
                color: color
              }}
            >
              {Config.menuTitle}
            </Text>
          ),
          tabBarLabel: "",
          tabBarLabelStyle: {},
        }}
      />
      <Stack.Screen
        name="PublicTable"
        component={PublicTableScreen}
        options={{
          title: "Halrem table",
          headerShown: true,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <Text
              style={{
                fontFamily: Config.theme.defaultFontFamily,
                textShadowColor: colorScheme === "dark" ? "white" : "black",
                textShadowOutline: 1,
                textShadowRadius: 3,
                color,
              }}
            >
              {Config.menuTitle}
            </Text>
          ),
          tabBarLabel: "",
          tabBarLabelStyle: {},
        }}
      />
      
      <Stack.Screen
        name="PublicComputer"
        component={PublicComputerScreen}
        options={{
          title: "RFX gaming",
          headerShown: true,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <Text
              style={{
                fontFamily: Config.theme.defaultFontFamily,
                textShadowColor: colorScheme === "dark" ? "white" : "black",
                textShadowOutline: 1,
                textShadowRadius: 3,
                color,
              }}
            >
              Harlem
            </Text>
          ),
          tabBarLabel: "",
          tabBarLabelStyle: {},
        }}
      />
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator();

const OperatorBottomTab = createBottomTabNavigator();


function OperatorBottomTabNavigator({ route }) {
  const colorScheme = useColorScheme();
  const activeTintColor = Colors[colorScheme].tint;

  return (
    <OperatorBottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: activeTintColor,
        tabBarLabelStyle: {
          position: "absolute",
          textAlignVertical: "center",
        },
      }}
    >
      <OperatorBottomTab.Screen
        name="TablesRoot"
        component={TablesRoot}
        options={({ navigation }) => ({
          title: "Tables",
          headerShown: false,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons
              name="chart-scatter-plot"
              size={24}
              color={color}
            />
          ),
          tabBarLabel: "",
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        })}
      />

      {Config.modules.delivery && <OperatorBottomTab.Screen
        name="DeliveriesRoot"
        component={DeliveriesRoot}
        options={({ navigation }) => ({
          title: "Deliveries",
          headerShown: false,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <MaterialIcons
              name="electric-bike"
              size={24}
              color={color}
            />
          ),
          tabBarLabel: "",
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        })}
      />}

      <OperatorBottomTab.Screen
        name="Orders"
        component={OrdersScreen}
        options={({ navigation }) => ({
          title: "Orders",
          headerShown: false,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => (
            <Octicons name="checklist" size={24} color={color} />
          ),
          tabBarLabel: "",
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        })}
      />

      
      {Config.modules.esports && <OperatorBottomTab.Screen
        name="GamingRoot"
        component={GamingRoot}
        options={{
          title: "Gaming",
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="computer" size={24} color={color} />
          ),
          tabBarLabel: '',
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        }}
      />}

      {Config.modules.vr && <OperatorBottomTab.Screen
        name="VRRoot"
        component={VRRoot}
        options={{
          title: "VR",
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name="virtual-reality" size={24} color={color} />
          ),
          tabBarLabel: '',
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        }}
      />}


      {Config.modules.benefits && <OperatorBottomTab.Screen
        name="OperatorBenefits"
        component={OperatorBenefitsScreen}
        options={{
          title: "Benefits",
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="emoji-food-beverage" size={24} color={color} />
          ),
          tabBarLabel: '',
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        }}
      /> }

      <OperatorBottomTab.Screen
        name="OperatorSettingsRoot"
        component={OperatorSettingsRoot}
        options={{
          title: "Settings",
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="settings" size={24} color={color} />
          ),
          tabBarLabel: '',
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarLabelStyle: { fontFamily: Config.theme.defaultFontFamily },
        }}
      />
    </OperatorBottomTab.Navigator>
  );
}

function BottomTabNavigator({ route }) {
  const colorScheme = useColorScheme();
  const activeTintColor = Colors[colorScheme].tint;

  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: activeTintColor,
        tabBarStyle: {},
        tabBarLabelStyle: {
          position: "absolute",
          textAlignVertical: "center",
        },
      }}
    >
      <BottomTab.Screen
        name="MenuRoot"
        component={MenuRoot}
        options={({ navigation }) => ({
          title: "Menu",
          headerShown: false,
          headerTitleStyle: { fontFamily: Config.theme.defaultFontFamily },
          tabBarIcon: ({ color }) => {
            
            return (
              <Text
                style={{
                  fontFamily: Config.theme.defaultFontFamily,
                  textShadowColor: colorScheme === "dark" ? "white" : "black",
                  textShadowOutline: 1,
                  textShadowRadius: 3,
                  textAlign: 'center',
                  width: 100,
                  justifyContent: 'center',
                  color,
                }}
              >{Config.menuTitle} ⭐</Text>
          )},
          tabBarLabel: "",
        })}
      />

      {Config.modules.vr && <BottomTab.Screen
        name="VR"
        component={VRScreen}
        options={{
          title: "HarlemVR",
          headerShown: false,
          tabBarIcon: ({ color }) => {
            return (
            <View><MaterialCommunityIcons
              name="virtual-reality"
              size={24}
              color={color}
            /><Text style={{position: 'absolute', right: -10, top: -7}}>⭐</Text></View>
          )},
          tabBarLabel: "",
          tabBarLabelStyle: {
            textShadowColor: colorScheme === "dark" ? "white" : "black",
            textShadowOutline: 1,
            textShadowRadius: colorScheme === "dark" ? 3 : 1,
          },
        }}
      /> }

      {Config.modules.esports && 
      <BottomTab.Screen
        name="Esports"
        component={EsportsScreen}
        options={{
          title: "RFX e-sports",
          headerShown: false,
          tabBarIcon: ({ color }) => <View><Logo1 active={color===activeTintColor} /><Text style={{position: 'absolute', right: 0, top: 0}}>⭐</Text></View>,
          tabBarLabel: "",
          tabBarLabelStyle: {
            textShadowColor: colorScheme === "dark" ? "white" : "black",
            textShadowOutline: 1,
            textShadowRadius: colorScheme === "dark" ? 3 : 1,
          },
        }}
      />}


      <BottomTab.Screen
        name="More"
        component={MoreScreen}
        options={{
          title: "More & settings",
          tabBarIcon: ({ color }) => <View><MaterialIcons name="more-horiz" size={40} color={color}  /><Text style={{position: 'absolute', right: -10, top: -5}}>⭐</Text></View>,
          tabBarLabel: "",
          tabBarLabelStyle: {
            textShadowColor: colorScheme === "dark" ? "white" : "black",
            textShadowOutline: 1,
            textShadowRadius: colorScheme === "dark" ? 3 : 1,
          },
        }}
      />
    </BottomTab.Navigator>
  );
}



function TabBarIcon(props) {
  return <FontAwesome size={30} style={{ marginBottom: -3, width: 50 }} {...props} />;
}
